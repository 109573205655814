import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const showsStyle = {
  fontFamily: `Ropa Sans, sans-serif`,
  textAlign: `center`,
  margin: `0 2rem`
};

const Shows = () => (
  <Layout>
    <div style={showsStyle}>
      <SEO title="Shows" />
      <h1 style={{ letterSpacing: `3px` }}>SHOWS</h1>
      <table>
        <thead>
          <td>Date</td>
          <td>Venue</td>
          <td>Description</td>
        </thead>
        <tbody>
          <tr>
            <td>Thursday January 23, 2020</td>
            <td>
              <a href="https://www.houseofblues.com/dallas/EventDetail?tmeventid=0&offerid=117255">
                House of Blues Dallas - Voodoo Stage
              </a>
            </td>
            <td>6 PM to 9 PM</td>
          </tr>
          <tr>
            <td>Friday February 7, 2020</td>
            <td>
              <a href="http://www.eight11place.com/frisco_live_music.html">
                eight | 11 place
              </a>
            </td>
            <td>6:30 PM to 10 PM</td>
          </tr>
          <tr>
            <td>Thursday April 9, 2020</td>
            <td>
              <a href="http://rickschophouse.com/lounge_origial.php">
                Rick's Chop House
              </a>
            </td>
            <td>7 PM to 11 PM</td>
          </tr>
        </tbody>
      </table>
    </div>
  </Layout>
)

export default Shows
